import Link from 'next/link';
import PropTypes from 'prop-types';

export default function HomeTags({ data }) {
    return (
        <section className={`section has-background-light`}>
            <div className="container has-text-centered">
                <div className="columns">
                    <div className="column is-8 is-offset-2">
                        <div className="buttons are-small is-centered">
                            {data &&
                                data.map(tag => (
                                    <Link
                                        key={tag.id}
                                        href={`/tags/${encodeURIComponent(tag.slug)}`}
                                    >
                                        <a className="button is-info is-inverted">{tag.name}</a>
                                    </Link>
                                ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

HomeTags.propTypes = {
    data: PropTypes.array,
};
