import Link from 'next/link';
import PropTypes from 'prop-types';
import Styles from './Navbar.module.scss';

export default function NavBar({ active }) {
    return (
        <nav className={`navbar is-light ${Styles.navbar}`}>
            <div className={`${Styles.links}`}>
                <ul>
                    <li>
                        <Link href="/">
                            <a
                                className={`is-unselectable is-size-5 ${
                                    active === 'home' ? 'has-text-info-dark' : ''
                                }`}
                            >
                                Home
                            </a>
                        </Link>
                    </li>
                    <li>
                        <Link href="/portfolio">
                            <a
                                className={`is-unselectable is-size-5 ${
                                    active === 'portfolio' ? 'has-text-info-dark' : ''
                                }`}
                            >
                                Portfolio
                            </a>
                        </Link>
                    </li>
                    <li>
                        <Link href="/about">
                            <a
                                className={`is-unselectable is-size-5 ${
                                    active === 'about' ? 'has-text-info-dark' : ''
                                }`}
                            >
                                About
                            </a>
                        </Link>
                    </li>
                    <li>
                        <Link href="/contact">
                            <a
                                className={`is-unselectable is-size-5 ${
                                    active === 'contact' ? 'has-text-info-dark' : ''
                                }`}
                            >
                                Contact
                            </a>
                        </Link>
                    </li>
                </ul>
            </div>
        </nav>
    );
}

NavBar.propTypes = {
    active: PropTypes.string,
};
