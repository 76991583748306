import Head from 'next/head';
import PropTypes from 'prop-types';
import Styles from './Layout.module.scss';
import Header from 'components/Header';
import NavBar from 'components/Navbar';
import Footer from 'components/Footer';
// import { motion } from 'framer-motion';
import { metaKeywords } from 'utils/constants';

export default function Layout({ seo, children, section, route }) {
    return (
        // <motion.div
        //     key={route}
        //     initial="exit"
        //     // initial={route && route === '/' ? 'initial' : 'exit'}
        //     animate="enter"
        //     exit="exit"
        //     variants={{
        //         initial: {
        //             opacity: 1,
        //         },
        //         enter: {
        //             opacity: 1,
        //             transition: { duration: 0.3, ease: [0.48, 0.15, 0.25, 0.96] },
        //         },
        //         exit: {
        //             opacity: 0,
        //             transition: { duration: 0.2, ease: [0.48, 0.15, 0.25, 0.96] },
        //         },
        //     }}
        // >
        <div className={`${Styles.layout} ${Styles[section]}`}>
            <Head>
                <meta
                    name="viewport"
                    content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, viewport-fit=cover"
                />
                <title>{seo.title || 'Bruno Domingos - Portfolio'}</title>
                <meta name="keywords" content={metaKeywords} />
                <meta
                    name="description"
                    content={seo.description || 'Bruno Domingos - Portfolio'}
                />
                <meta name="author" content="Bruno Domingos" />
            </Head>

            <Header section={section} />
            <NavBar active={section} />

            <main className={`${Styles.main}`}>{children}</main>

            <Footer />
        </div>
        // </motion.div>
    );
}

Layout.propTypes = {
    seo: PropTypes.object,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    section: PropTypes.string,
    route: PropTypes.string,
};
